/**
 *
 * @param {jQuery} $elements
 * @param {object} cssProps
 */
export function view_setCssPropsForElement($elements, cssProps){
  $elements.css(cssProps);
}

/**
 *
 * @param cssVal
 * @return {string}
 */
export function view_cssConvertForPx(cssVal){
  if(cssVal===''){
    return cssVal;
  }
  if(['auto','px','%',''].indexOf(cssVal)===-1){
    return cssVal + 'px';
  }

  return cssVal;
}

